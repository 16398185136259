.SubmissionModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 100;
}

.ModalInfoCont {
  position: relative;
  width: 300px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--FireYellow);
  border-radius: 10px;
}

.CloseModal {
  position: absolute;
  top: 15px;
  right: 15px;
}

.CloseModal:hover {
  cursor: pointer;
}

.ModalInfo1 {
  position: absolute;
  width: 250px;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  font-family: var(--montserrat);
  text-align: center;
}

.ModalInfo2 {
  position: absolute;
  width: 250px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-family: var(--montserrat);
  text-align: center;
}