.NavBar {
  width: 100%;
  height: 175px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

.NavBar.scrolled {
  height: 75px;
  background: var(--darkGrey);
}

.Logo {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 125px;
  transition: all 0.3s ease-in-out;
}

.NavBar.scrolled .Logo {
  width: 60px
}

.NavBar .LogoName {
  position: absolute;
  top: 50%;
  left: 165px;
  transform: translateY(-50%);
  color: var(--white);
  font-family: var(--metalMania);
  font-size: 2rem;
  transition: all 0.3s ease-in-out;
}

.NavBar.scrolled .LogoName {
  left: 100px;
  font-size: 1.5rem;
}

.NavLinksCont {
  position: absolute;
  top: 50px;
  right: 40px;
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.NavBar.scrolled .NavLinksCont {
  top: 25.5px;
}

.NavLinks {
  list-style: none;
  font-family: var(--lato);
  font-size: 1.25rem;
}

.NavLinks li a {
  text-decoration: none;
}

.NavLinks a:visited {
  color: var(--white);
}

.NavLinks li {
  display: inline-block;
  padding: 0 10px;
  color: var(--white);
}

.NavLinks li:hover,
.NavLinks li:hover a {
  color: var(--FireYellow);
  cursor: pointer;
}

.hamburger {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50px;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.NavBar.scrolled .hamburger {
  top: 12.5px;
  transform: translateY(0);
}

.hamburger span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
  height: 4px;
  background: var(--white);
  display: block;
  border-radius: 2px;
  transition: 0.5s 0.5s;
}

.hamburger span:before{
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: block;
  border-radius: 2px;
  transition: top 0.5s 0.5s, transform 0.5s 0s;
}

.hamburger span:after{
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: block;
  border-radius: 2px;
  transition: top 0.5s 0.5s, transform 0.5s 0s;
}

.hamburger.active span{
  background: transparent;
}
        
.hamburger.active span:before{
    top: 0;
    transform: rotate(45deg);
    transition: top 0.5s 0s, transform 0.5s 0.5s;
}
        
.hamburger.active span:after{
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.5s 0s, transform 0.5s 0.5s;
}

.mobileMenu {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  right: -100%;
  background: var(--darkGrey);
  color: var(--white);
  transition: all 0.6s ease-in-out;
  z-index: -1;
}

.mobileMenu.open {
  right: 0;
}

.mobileNavLinks {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  font-family: var(--lato);
  font-size: 1.25rem;
  text-align: center;
}

.mobileNavLinks li a {
  text-decoration: none;
}

.mobileNavLinks li {
  padding: 1rem 0;
  border-bottom: 2px solid var(--white);
  color: var(--white);
}

.mobileNavLinks li:hover,
.mobileNavLinks li:hover a {
  color: var(--FireYellow);
  cursor: pointer;
}

.mobileNavLinks a:visited {
  color: var(--white);
}

.mobileNavLinks li:last-child {
  border-bottom: none;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 650px) {
  .NavBar {
    height: 100px;
  }

  .Logo {
    width: 75px;
  }

  .NavBar .LogoName {
    left: 115px;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 475px) {
  .NavBar {
    height: 75px;
  }

  .Logo,
  .NavBar.scrolled .Logo {
    width: 50px;
    left: 15px;
  }

  .NavBar .LogoName,
  .NavBar.scrolled .LogoName {
    left: 80px;
    font-size: 1.25rem;
  }

  .hamburger {
    top: 50%;
  }
}

@media only screen and (max-width: 375px) {
  .Logo,
  .NavBar.scrolled .Logo {
    left: 10px;
  }

  .NavBar .LogoName,
  .NavBar.scrolled .LogoName {
    left: 70px;
    font-size: 1.25rem;
  }
}