.LoginCont {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url("/Images/Hero.jpg") center/cover fixed;
  padding: 200px 0;
  text-align: center;
}

.LoginFormCont {
  display: grid;
  grid-template-rows: auto auto;
  gap: 1.5rem;
}

.LoginTitle {
  color: var(--white);
  font-family: var(--fjallaOne);
  font-size: 3rem;
}

.LoginForm {
  margin: 0 auto;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 0.75rem;
}

.LoginForm input {
  width: 400px;
  height: 35px;
  font-family: var(--montserrat);
  border: none;
  text-indent: 5px;
  border-radius: 2px;
}

.LoginForm input:focus {
  outline: none;
}

.SubmitLogin {
  width: 100px;
  font-family: var(--lato);
  padding: 10px 20px;
  color: var(--FireYellow);
  background: var(--black);
  border: none;
  font-size: 1rem;
  border-radius: 3px;
  text-decoration: none;
}

.SubmitLogin:hover {
  background: var(--FireYellow);
  color: var(--black);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 475px) {

  .LoginForm input {
    width: 300px;
  }

}