@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lato:wght@700&family=Metal+Mania&family=Montserrat&family=Roboto:wght@500&display=swap);
/* RESETS */
* {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
}

:root {
  --black: rgb(0,0,0);
  --darkGrey: rgb(15,15,15);
  --Grey: rgb(129,129,129);
  --white: rgb(255,255,255);
  --FireYellow: rgb(253,204,14);
  --FacebookBlue: rgb(66,103,178);
  --TwitterBlue: rgb(44,170,225);
  --YoutubeRed: rgb(255,0,0);
  --metalMania: 'Metal Mania', cursive;
  --lato: 'Lato', sans-serif;
  --fjallaOne: 'Fjalla One', sans-serif;
  --roboto: 'Roboto', sans-serif;
  --montserrat: 'Montserrat', sans-serif;
}
.NavBar {
  width: 100%;
  height: 175px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

.NavBar.scrolled {
  height: 75px;
  background: var(--darkGrey);
}

.Logo {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 125px;
  transition: all 0.3s ease-in-out;
}

.NavBar.scrolled .Logo {
  width: 60px
}

.NavBar .LogoName {
  position: absolute;
  top: 50%;
  left: 165px;
  transform: translateY(-50%);
  color: var(--white);
  font-family: var(--metalMania);
  font-size: 2rem;
  transition: all 0.3s ease-in-out;
}

.NavBar.scrolled .LogoName {
  left: 100px;
  font-size: 1.5rem;
}

.NavLinksCont {
  position: absolute;
  top: 50px;
  right: 40px;
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.NavBar.scrolled .NavLinksCont {
  top: 25.5px;
}

.NavLinks {
  list-style: none;
  font-family: var(--lato);
  font-size: 1.25rem;
}

.NavLinks li a {
  text-decoration: none;
}

.NavLinks a:visited {
  color: var(--white);
}

.NavLinks li {
  display: inline-block;
  padding: 0 10px;
  color: var(--white);
}

.NavLinks li:hover,
.NavLinks li:hover a {
  color: var(--FireYellow);
  cursor: pointer;
}

.hamburger {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50px;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.NavBar.scrolled .hamburger {
  top: 12.5px;
  transform: translateY(0);
}

.hamburger span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
  height: 4px;
  background: var(--white);
  display: block;
  border-radius: 2px;
  transition: 0.5s 0.5s;
}

.hamburger span:before{
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: block;
  border-radius: 2px;
  transition: top 0.5s 0.5s, transform 0.5s 0s;
}

.hamburger span:after{
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: block;
  border-radius: 2px;
  transition: top 0.5s 0.5s, transform 0.5s 0s;
}

.hamburger.active span{
  background: transparent;
}
        
.hamburger.active span:before{
    top: 0;
    transform: rotate(45deg);
    transition: top 0.5s 0s, transform 0.5s 0.5s;
}
        
.hamburger.active span:after{
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.5s 0s, transform 0.5s 0.5s;
}

.mobileMenu {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  right: -100%;
  background: var(--darkGrey);
  color: var(--white);
  transition: all 0.6s ease-in-out;
  z-index: -1;
}

.mobileMenu.open {
  right: 0;
}

.mobileNavLinks {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  font-family: var(--lato);
  font-size: 1.25rem;
  text-align: center;
}

.mobileNavLinks li a {
  text-decoration: none;
}

.mobileNavLinks li {
  padding: 1rem 0;
  border-bottom: 2px solid var(--white);
  color: var(--white);
}

.mobileNavLinks li:hover,
.mobileNavLinks li:hover a {
  color: var(--FireYellow);
  cursor: pointer;
}

.mobileNavLinks a:visited {
  color: var(--white);
}

.mobileNavLinks li:last-child {
  border-bottom: none;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 650px) {
  .NavBar {
    height: 100px;
  }

  .Logo {
    width: 75px;
  }

  .NavBar .LogoName {
    left: 115px;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 475px) {
  .NavBar {
    height: 75px;
  }

  .Logo,
  .NavBar.scrolled .Logo {
    width: 50px;
    left: 15px;
  }

  .NavBar .LogoName,
  .NavBar.scrolled .LogoName {
    left: 80px;
    font-size: 1.25rem;
  }

  .hamburger {
    top: 50%;
  }
}

@media only screen and (max-width: 375px) {
  .Logo,
  .NavBar.scrolled .Logo {
    left: 10px;
  }

  .NavBar .LogoName,
  .NavBar.scrolled .LogoName {
    left: 70px;
    font-size: 1.25rem;
  }
}
.SubmissionModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 100;
}

.ModalInfoCont {
  position: relative;
  width: 300px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--FireYellow);
  border-radius: 10px;
}

.CloseModal {
  position: absolute;
  top: 15px;
  right: 15px;
}

.CloseModal:hover {
  cursor: pointer;
}

.ModalInfo1 {
  position: absolute;
  width: 250px;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  font-family: var(--montserrat);
  text-align: center;
}

.ModalInfo2 {
  position: absolute;
  width: 250px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-family: var(--montserrat);
  text-align: center;
}
.CustomOrdersCont {
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
}

.CustomOrdersTitleCont {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 101px) 90px;
}

.CustomOrdersTitle,
.CustomOrdersTitle2,
.CustomOrdersTitleDes {
  color: var(--white);
}

.CustomOrdersTitle {
  font-family: var(--fjallaOne);
  font-size: 5rem;
}

.CustomOrdersTitle2 {
  font-family: var(--fjallaOne);
  font-size: 5rem;
}

.CustomOrdersTitleDes {
  font-family: var(--roboto);
  font-size: 2.25rem;
}

.CutomOrderRequestQuestionForm {
  width: 400px;
  justify-self: end;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 35px) 100px 40px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.CutomOrderRequestQuestionForm input,
#CustomOrder {
  width: 400px;
  height: 35px;
  font-family: var(--montserrat);
  border: none;
  text-indent: 5px;
  border-radius: 2px;
}

.CutomOrderRequestQuestionForm input:focus,
#CustomOrder:focus {
  outline: none;
}

#CustomOrder {
  height: 100px;
}

.Submit {
  font-family: var(--lato);
  padding: 10px 20px;
  color: var(--black);
  background: var(--white);
  border: none;
  font-size: 1rem;
  border-radius: 3px;
  text-decoration: none;
}

.Submit:hover {
  background: var(--FireYellow);
  color: var(--black);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1288px) {
  .CustomOrdersCont {
    width: 90%;
  }
}

@media only screen and (max-width: 1020px) {
  .CustomOrdersCont {
    grid-template-columns: 345px 1fr;
  }

  .CustomOrdersTitleCont {
    grid-template-rows: repeat(2, 70px) 90px;
  }

  .CustomOrdersTitle {
    font-size: 3.5rem;
  }
  
  .CustomOrdersTitle2 {
    font-size: 3.5rem;
  }
  
  .CustomOrdersTitleDes {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 900px) {
  .CustomOrdersCont {
    width: 345px;
    grid-template-columns: 345px;
    grid-row-gap: 25px;
    row-gap: 25px;
  }

  .CutomOrderRequestQuestionForm,
  .CutomOrderRequestQuestionForm input,
  #CustomOrder {
    width: 345px;
  }
}

@media only screen and (max-width: 400px) {
  .CustomOrdersCont {
    width: 288px;
    grid-template-columns: 288px;
  }

  .CustomOrdersTitleCont {
    grid-template-columns: 288px;
    grid-template-rows: repeat(2, 50px) 82px;
  }

  .CustomOrdersTitle {
    font-size: 2.5rem;
  }
  
  .CustomOrdersTitle2 {
    font-size: 2.5rem;
  }
  
  .CustomOrdersTitleDes {
    font-size: 1.5rem;
  }

  .CutomOrderRequestQuestionForm,
  .CutomOrderRequestQuestionForm input,
  #CustomOrder {
    width: 288px;
  }
}
.Home {
  width: 100%;
}

/* Hero Container */
.HeroCont {
  width: 100%;
  height: 100vh;
  background: url("/Images/Hero.jpg") center/cover fixed;
}

.HeroTitleCont {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  text-align: center;
}

.HeroTitle {
 font-family: var(--fjallaOne);
 font-size: 8rem;
}

.HeroTitleDesc {
 font-family: var(--roboto);
 font-size: 3rem;
}

.HeroScrollDown {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--white);
}

/* Makers Container */
.MakersCont {
  width: 100%;
  padding: 100px 0;
  background: url("/Images/Flames.svg") center/cover;
}

.MeetMakersCont {
  box-sizing: border-box;
  width: 70%;
  margin: 0 auto;
  background: var(--white);
  display: grid;
  grid-template-columns: 325px 1fr;
  grid-template-rows: 20px repeat(3, 40px) 1fr 50px 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
  justify-items: center;
  align-items: center;
}

.BrandonDave {
  width: 325px;
  margin: 0;
  padding: 0;
  grid-column: 1 / 2;
  grid-row: 1 / 8;
}

.MakersTitle {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  font-family: var(--roboto);
}

.MakersTitle2 {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  font-family: var(--roboto);
}

.MakersTitle3 {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  font-family: var(--metalMania);
}

.AboutMakers {
  grid-column: 2 / 3;
  grid-row: 5 / 6;
  font-family: var(--montserrat);
  line-height: 2rem;
  margin-right: 20px;
}

.MeetMakersBtn {
  grid-column: 2 / 3;
  grid-row: 6 / 7;
  font-family: var(--lato);
  padding: 10px 20px;
  color: var(--white);
  background: var(--black);
  border: none;
  font-size: 1rem;
  border-radius: 3px;
  text-decoration: none;
}

.MeetMakersBtn:hover {
  background: var(--FireYellow);
  color: var(--black);
}

/* Custom Orders Section */
.CustomOrdersSection {
  width: 100%;
  background: url("/Images/FlamesBackground.jpg") left top/cover fixed;
  padding: 100px 0;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1160px) {
  .MeetMakersCont {
    width: 80%;
  }
}

@media only screen and (max-width: 1020px) {
  .MeetMakersCont {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .MeetMakersCont {
    grid-template-columns: 250px 1fr;
    grid-template-rows: repeat(3, 40px) 1fr 50px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 0;
    row-gap: 0;
  }

  .BrandonDave {
    width: 250px;
  }

  .MakersTitle {
    grid-row: 1 / 2;
  }

  .MakersTitle2 {
    grid-row: 2 / 3;
  }

  .MakersTitle3 {
    grid-row: 3 / 4;
  }

  .AboutMakers {
    grid-row: 4 / 5;
  }

  .MeetMakersBtn {
    grid-row: 5 / 6;
  }
}

@media only screen and (max-width: 818px) {
  .MeetMakersCont {
    width: 70%;
    grid-template-columns: 1fr;
    grid-template-rows: 375px 20px repeat(3, 40px) 1fr 80px;
  }

  .BrandonDave {
    width: 250px;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .MakersTitle {
    font-size: 1.5rem;
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  .MakersTitle2 {
    font-size: 1.5rem;
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }

  .MakersTitle3 {
    font-size: 1.5rem;
    grid-row: 5 / 6;
    grid-column: 1 / 2;
  }

  .AboutMakers {
    grid-row: 6 / 7;
    grid-column: 1 / 2;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .MeetMakersBtn {
    grid-row: 7 / 8;
    grid-column: 1 / 2;
  }
}

@media only screen and (max-width: 760px) {
  .HeroTitle {
    font-size: 5rem;
  }

  .HeroTitleDesc {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .MeetMakersCont {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .HeroTitle {
    font-size: 3.5rem;
  }

  .HeroTitleDesc {
    font-size: 1.5rem;
  }
}
.About {  
  width: 100%;
}

/* ABOUT US HERO */
.AboutUsHeroSect {
  position: relative;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url("/Images/ForgingAnvilHammer2.jpg") top/cover fixed;
}

.AboutUsTitle {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  font-family: var(--fjallaOne);
  font-size: 8rem;
  text-align: center;
}

/* ABOUT US DESC */
.AboutUsDescSect {
  width: 100%;
  background: var(--white);
  padding: 100px 0;
}

.AboutUsOutterGrid {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-column-gap: 75px;
  -webkit-column-gap: 75px;
          column-gap: 75px;
}

.AboutUsInnerGrid {
  display: grid;
  grid-row-gap: 50px;
  row-gap: 50px;
}

.AboutUsDescTitleCont,
.OurGoalsCont {
  text-align: center;
}

.AboutUsDescTitle,
.OurGoalsTitle {
  font-family: var(--roboto);
}

.AboutUsDesc,
.OurGoalsDesc {
  font-family: var(--montserrat);
  text-align: center;
  margin-top: 1rem;
  line-height: 2rem;
}

.HammerIcon {
  width: 400px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1100px) {
  .AboutUsOutterGrid {
    grid-template-columns: 1fr;
    grid-template-rows: 400px 1fr;
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .HammerIconCont {
    grid-row: 1 / 2;
    text-align: center;
  }
}

@media only screen and (max-width: 818px) {
  .AboutUsOutterGrid {
    grid-template-rows: 250px 1fr;
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .AboutUsInnerGrid {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .HammerIcon {
    width: 250px;
  }

  .AboutUsDescTitle,
  .OurGoalsTitle {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 760px) {
  .AboutUsTitle {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 500px) {
  .AboutUsOutterGrid {
    grid-template-rows: 250px 1fr;
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .AboutUsInnerGrid {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .AboutUsTitle {
    font-size: 3.5rem;
  }

  .HammerIcon {
    width: 250px;
  }
}
.Contact {
  width: 100%;
  background: url("/Images/FlamesBackground.jpg") center/cover;
  padding: 250px 0 100px;
}

@media only screen and (max-width: 650px) {
  .Contact {
    padding: 175px 0 100px;
  }
}

@media only screen and (max-width: 475px) {
  .Contact {
    padding: 150px 0 100px;
  }
}
.LoginCont {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url("/Images/Hero.jpg") center/cover fixed;
  padding: 200px 0;
  text-align: center;
}

.LoginFormCont {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.LoginTitle {
  color: var(--white);
  font-family: var(--fjallaOne);
  font-size: 3rem;
}

.LoginForm {
  margin: 0 auto;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.LoginForm input {
  width: 400px;
  height: 35px;
  font-family: var(--montserrat);
  border: none;
  text-indent: 5px;
  border-radius: 2px;
}

.LoginForm input:focus {
  outline: none;
}

.SubmitLogin {
  width: 100px;
  font-family: var(--lato);
  padding: 10px 20px;
  color: var(--FireYellow);
  background: var(--black);
  border: none;
  font-size: 1rem;
  border-radius: 3px;
  text-decoration: none;
}

.SubmitLogin:hover {
  background: var(--FireYellow);
  color: var(--black);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 475px) {

  .LoginForm input {
    width: 300px;
  }

}
.CommentSummaryCont {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.CommentSummaryCont a {
  text-decoration: none;
}

.CommentCard {
  background: var(--FireYellow);
  color: var(--black);
  padding: 15px;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  row-gap: 10px;
  border-radius: 5px;
}

.CustomerComment,
.CommentDate,
.CustomerFirstName,
.CustomerLastName,
.CustomerEmail {
  font-family: var(--montserrat);
}

.CustomerCommentTitle,
.CommentDateTitle,
.CustomerFirstNameTitle,
.CustomerLastNameTitle,
.CustomerEmailTitle {
  font-family: var(--roboto);
  margin-bottom: 5px;
}

.CustomerCommentCont,
.CommentDateCont,
.CustomerFirstNameCont,
.CustomerLastNameCont {
  border-bottom: 1px solid var(--darkGrey);
  padding-bottom: 15px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 550px) {

  .CommentSummaryCont {
    width: 90%;
  }

}

@media only screen and (max-width: 450px) {

  .CustomerCommentCont p,
  .CommentDateCont p,
  .CustomerFirstNameCont p,
  .CustomerLastNameCont p,
  .CustomerEmailCont p {
    font-size: 0.85rem;
  }

}
.DashboardCont {
  width: 100%;
  background: var(--darkGrey);
  padding: 225px 0;
}
.CommentDetailCard {
  width: 70%;
  margin: 0 auto;
  background: var(--FireYellow);
  padding: 15px;
  border-radius: 5px;
}

.CommentDetailCont {
  position: relative;
}

.CommentDetailDateCont,
.CommentDetailNameCont,
.CommentDetailEmailCont {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--darkGrey);
}

.CommentDetailCommentCont {
  margin-bottom: 30px;
}

.CommentDetailDateTitle,
.CommentDetailNameTitle,
.CommentDetailEmailTitle,
.CommentDetailCommentTitle {
  font-family: var(--roboto);
}

.CommentDetailDateTitle span,
.CommentDetailName,
.CommentDetailEmail,
.CommentDetailComment {
  font-family: var(--montserrat);
}

.AddNoteToComment {
  position: relative;
  bottom: 0;
  left: 0;
  font-family: var(--lato);
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  background: var(--TwitterBlue);
  color: var(--black);
}

.DeleteNote {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: var(--lato);
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  background: var(--YoutubeRed);
  color: var(--black);
}

.AddNoteToComment:hover,
.DeleteNote:hover {
  cursor: pointer;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 550px) {
  .CommentDetailCard {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .CommentDetailDateTitle,
  .CommentDetailNameTitle,
  .CommentDetailName,
  .CommentDetailEmailTitle,
  .CommentDetailEmail,
  .CommentDetailCommentTitle,
  .CommentDetailComment {
    font-size: 0.85rem;
  }
}
.CommentDetailSummaryCont {
  background: var(--darkGrey);
  width: 100%;
  padding: 225px 0;
}
.Footer {
  width: 100%;
  padding: 100px 0;
  background: var(--darkGrey);
  position: relative;
}

.FooterCont {
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 271px 1fr;
}

.FooterLogoCont {
  display: grid;
  grid-template-rows: 45px 250px;
  justify-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.FooterLogoName {
  font-family: var(--metalMania);
  font-size: 2.25rem;
  color: var(--white);
}

.FooterLogo {
  width: 250px;
}

.FooterIconCont {
  width: 500px;
  justify-self: end;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: end;
  align-items: center;
}

a .FooterIcon {
  color: var(--white);
  font-size: 5rem;
}

.EmailIcon:hover {
  color: var(--FireYellow);
}

.FacebookIcon:hover {
  color: var(--FacebookBlue);
}

.YoutubeIcon:hover {
  color: var(--YoutubeRed);
}

.TwitterIcon:hover {
  color: var(--TwitterBlue);
}

.FooterCopyWrite {
  position: absolute;
  width: 386px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--white);
  font-family: var(--montserrat);
  text-decoration: none;
  cursor: default;
}

.FooterCopyWrite {
  /* cursor: none; */
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1288px) {
  .FooterCont {
    width: 90%;
  }
}

@media only screen and (max-width: 883px) {
  .FooterCont {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    gap: 3rem;
  }

  .FooterIconCont {
    justify-self: center;
    justify-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .FooterCont {
    width: 100%;
  }
  
  .FooterLogoCont {
    grid-template-rows: 1.75rem 210px;
  }

  .FooterLogoName {
    font-size: 1.75rem;
  }

  .FooterLogo {
    width: 210px;
  }

  .FooterIconCont {
    width: 282px;
  }

  a .FooterIcon {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 425px) {
  .FooterCopyWrite {
    width: 289px;
    font-size: .75rem;
  }
}

