.Contact {
  width: 100%;
  background: url("/Images/FlamesBackground.jpg") center/cover;
  padding: 250px 0 100px;
}

@media only screen and (max-width: 650px) {
  .Contact {
    padding: 175px 0 100px;
  }
}

@media only screen and (max-width: 475px) {
  .Contact {
    padding: 150px 0 100px;
  }
}