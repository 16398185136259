.About {  
  width: 100%;
}

/* ABOUT US HERO */
.AboutUsHeroSect {
  position: relative;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url("/Images/ForgingAnvilHammer2.jpg") top/cover fixed;
}

.AboutUsTitle {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  font-family: var(--fjallaOne);
  font-size: 8rem;
  text-align: center;
}

/* ABOUT US DESC */
.AboutUsDescSect {
  width: 100%;
  background: var(--white);
  padding: 100px 0;
}

.AboutUsOutterGrid {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 400px;
  column-gap: 75px;
}

.AboutUsInnerGrid {
  display: grid;
  row-gap: 50px;
}

.AboutUsDescTitleCont,
.OurGoalsCont {
  text-align: center;
}

.AboutUsDescTitle,
.OurGoalsTitle {
  font-family: var(--roboto);
}

.AboutUsDesc,
.OurGoalsDesc {
  font-family: var(--montserrat);
  text-align: center;
  margin-top: 1rem;
  line-height: 2rem;
}

.HammerIcon {
  width: 400px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1100px) {
  .AboutUsOutterGrid {
    grid-template-columns: 1fr;
    grid-template-rows: 400px 1fr;
    row-gap: 100px;
  }

  .HammerIconCont {
    grid-row: 1 / 2;
    text-align: center;
  }
}

@media only screen and (max-width: 818px) {
  .AboutUsOutterGrid {
    grid-template-rows: 250px 1fr;
    row-gap: 75px;
  }

  .AboutUsInnerGrid {
    row-gap: 30px;
  }

  .HammerIcon {
    width: 250px;
  }

  .AboutUsDescTitle,
  .OurGoalsTitle {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 760px) {
  .AboutUsTitle {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 500px) {
  .AboutUsOutterGrid {
    grid-template-rows: 250px 1fr;
    row-gap: 75px;
  }

  .AboutUsInnerGrid {
    row-gap: 30px;
  }

  .AboutUsTitle {
    font-size: 3.5rem;
  }

  .HammerIcon {
    width: 250px;
  }
}