.CommentSummaryCont {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto;
  row-gap: 20px;
}

.CommentSummaryCont a {
  text-decoration: none;
}

.CommentCard {
  background: var(--FireYellow);
  color: var(--black);
  padding: 15px;
  display: grid;
  grid-template-rows: auto;
  row-gap: 10px;
  border-radius: 5px;
}

.CustomerComment,
.CommentDate,
.CustomerFirstName,
.CustomerLastName,
.CustomerEmail {
  font-family: var(--montserrat);
}

.CustomerCommentTitle,
.CommentDateTitle,
.CustomerFirstNameTitle,
.CustomerLastNameTitle,
.CustomerEmailTitle {
  font-family: var(--roboto);
  margin-bottom: 5px;
}

.CustomerCommentCont,
.CommentDateCont,
.CustomerFirstNameCont,
.CustomerLastNameCont {
  border-bottom: 1px solid var(--darkGrey);
  padding-bottom: 15px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 550px) {

  .CommentSummaryCont {
    width: 90%;
  }

}

@media only screen and (max-width: 450px) {

  .CustomerCommentCont p,
  .CommentDateCont p,
  .CustomerFirstNameCont p,
  .CustomerLastNameCont p,
  .CustomerEmailCont p {
    font-size: 0.85rem;
  }

}