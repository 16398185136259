.CustomOrdersCont {
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 450px 1fr;
  column-gap: 50px;
}

.CustomOrdersTitleCont {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 101px) 90px;
}

.CustomOrdersTitle,
.CustomOrdersTitle2,
.CustomOrdersTitleDes {
  color: var(--white);
}

.CustomOrdersTitle {
  font-family: var(--fjallaOne);
  font-size: 5rem;
}

.CustomOrdersTitle2 {
  font-family: var(--fjallaOne);
  font-size: 5rem;
}

.CustomOrdersTitleDes {
  font-family: var(--roboto);
  font-size: 2.25rem;
}

.CutomOrderRequestQuestionForm {
  width: 400px;
  justify-self: end;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 35px) 100px 40px;
  row-gap: 20px;
}

.CutomOrderRequestQuestionForm input,
#CustomOrder {
  width: 400px;
  height: 35px;
  font-family: var(--montserrat);
  border: none;
  text-indent: 5px;
  border-radius: 2px;
}

.CutomOrderRequestQuestionForm input:focus,
#CustomOrder:focus {
  outline: none;
}

#CustomOrder {
  height: 100px;
}

.Submit {
  font-family: var(--lato);
  padding: 10px 20px;
  color: var(--black);
  background: var(--white);
  border: none;
  font-size: 1rem;
  border-radius: 3px;
  text-decoration: none;
}

.Submit:hover {
  background: var(--FireYellow);
  color: var(--black);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1288px) {
  .CustomOrdersCont {
    width: 90%;
  }
}

@media only screen and (max-width: 1020px) {
  .CustomOrdersCont {
    grid-template-columns: 345px 1fr;
  }

  .CustomOrdersTitleCont {
    grid-template-rows: repeat(2, 70px) 90px;
  }

  .CustomOrdersTitle {
    font-size: 3.5rem;
  }
  
  .CustomOrdersTitle2 {
    font-size: 3.5rem;
  }
  
  .CustomOrdersTitleDes {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 900px) {
  .CustomOrdersCont {
    width: 345px;
    grid-template-columns: 345px;
    row-gap: 25px;
  }

  .CutomOrderRequestQuestionForm,
  .CutomOrderRequestQuestionForm input,
  #CustomOrder {
    width: 345px;
  }
}

@media only screen and (max-width: 400px) {
  .CustomOrdersCont {
    width: 288px;
    grid-template-columns: 288px;
  }

  .CustomOrdersTitleCont {
    grid-template-columns: 288px;
    grid-template-rows: repeat(2, 50px) 82px;
  }

  .CustomOrdersTitle {
    font-size: 2.5rem;
  }
  
  .CustomOrdersTitle2 {
    font-size: 2.5rem;
  }
  
  .CustomOrdersTitleDes {
    font-size: 1.5rem;
  }

  .CutomOrderRequestQuestionForm,
  .CutomOrderRequestQuestionForm input,
  #CustomOrder {
    width: 288px;
  }
}