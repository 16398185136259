.CommentDetailCard {
  width: 70%;
  margin: 0 auto;
  background: var(--FireYellow);
  padding: 15px;
  border-radius: 5px;
}

.CommentDetailCont {
  position: relative;
}

.CommentDetailDateCont,
.CommentDetailNameCont,
.CommentDetailEmailCont {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--darkGrey);
}

.CommentDetailCommentCont {
  margin-bottom: 30px;
}

.CommentDetailDateTitle,
.CommentDetailNameTitle,
.CommentDetailEmailTitle,
.CommentDetailCommentTitle {
  font-family: var(--roboto);
}

.CommentDetailDateTitle span,
.CommentDetailName,
.CommentDetailEmail,
.CommentDetailComment {
  font-family: var(--montserrat);
}

.AddNoteToComment {
  position: relative;
  bottom: 0;
  left: 0;
  font-family: var(--lato);
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  background: var(--TwitterBlue);
  color: var(--black);
}

.DeleteNote {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: var(--lato);
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  background: var(--YoutubeRed);
  color: var(--black);
}

.AddNoteToComment:hover,
.DeleteNote:hover {
  cursor: pointer;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 550px) {
  .CommentDetailCard {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .CommentDetailDateTitle,
  .CommentDetailNameTitle,
  .CommentDetailName,
  .CommentDetailEmailTitle,
  .CommentDetailEmail,
  .CommentDetailCommentTitle,
  .CommentDetailComment {
    font-size: 0.85rem;
  }
}