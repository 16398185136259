.Footer {
  width: 100%;
  padding: 100px 0;
  background: var(--darkGrey);
  position: relative;
}

.FooterCont {
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 271px 1fr;
}

.FooterLogoCont {
  display: grid;
  grid-template-rows: 45px 250px;
  justify-items: center;
  gap: 1rem;
}

.FooterLogoName {
  font-family: var(--metalMania);
  font-size: 2.25rem;
  color: var(--white);
}

.FooterLogo {
  width: 250px;
}

.FooterIconCont {
  width: 500px;
  justify-self: end;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: end;
  align-items: center;
}

a .FooterIcon {
  color: var(--white);
  font-size: 5rem;
}

.EmailIcon:hover {
  color: var(--FireYellow);
}

.FacebookIcon:hover {
  color: var(--FacebookBlue);
}

.YoutubeIcon:hover {
  color: var(--YoutubeRed);
}

.TwitterIcon:hover {
  color: var(--TwitterBlue);
}

.FooterCopyWrite {
  position: absolute;
  width: 386px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--white);
  font-family: var(--montserrat);
  text-decoration: none;
  cursor: default;
}

.FooterCopyWrite {
  /* cursor: none; */
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1288px) {
  .FooterCont {
    width: 90%;
  }
}

@media only screen and (max-width: 883px) {
  .FooterCont {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .FooterIconCont {
    justify-self: center;
    justify-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .FooterCont {
    width: 100%;
  }
  
  .FooterLogoCont {
    grid-template-rows: 1.75rem 210px;
  }

  .FooterLogoName {
    font-size: 1.75rem;
  }

  .FooterLogo {
    width: 210px;
  }

  .FooterIconCont {
    width: 282px;
  }

  a .FooterIcon {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 425px) {
  .FooterCopyWrite {
    width: 289px;
    font-size: .75rem;
  }
}