.Home {
  width: 100%;
}

/* Hero Container */
.HeroCont {
  width: 100%;
  height: 100vh;
  background: url("/Images/Hero.jpg") center/cover fixed;
}

.HeroTitleCont {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  text-align: center;
}

.HeroTitle {
 font-family: var(--fjallaOne);
 font-size: 8rem;
}

.HeroTitleDesc {
 font-family: var(--roboto);
 font-size: 3rem;
}

.HeroScrollDown {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--white);
}

/* Makers Container */
.MakersCont {
  width: 100%;
  padding: 100px 0;
  background: url("/Images/Flames.svg") center/cover;
}

.MeetMakersCont {
  box-sizing: border-box;
  width: 70%;
  margin: 0 auto;
  background: var(--white);
  display: grid;
  grid-template-columns: 325px 1fr;
  grid-template-rows: 20px repeat(3, 40px) 1fr 50px 20px;
  column-gap: 20px;
  row-gap: 10px;
  justify-items: center;
  align-items: center;
}

.BrandonDave {
  width: 325px;
  margin: 0;
  padding: 0;
  grid-column: 1 / 2;
  grid-row: 1 / 8;
}

.MakersTitle {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  font-family: var(--roboto);
}

.MakersTitle2 {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  font-family: var(--roboto);
}

.MakersTitle3 {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  font-family: var(--metalMania);
}

.AboutMakers {
  grid-column: 2 / 3;
  grid-row: 5 / 6;
  font-family: var(--montserrat);
  line-height: 2rem;
  margin-right: 20px;
}

.MeetMakersBtn {
  grid-column: 2 / 3;
  grid-row: 6 / 7;
  font-family: var(--lato);
  padding: 10px 20px;
  color: var(--white);
  background: var(--black);
  border: none;
  font-size: 1rem;
  border-radius: 3px;
  text-decoration: none;
}

.MeetMakersBtn:hover {
  background: var(--FireYellow);
  color: var(--black);
}

/* Custom Orders Section */
.CustomOrdersSection {
  width: 100%;
  background: url("/Images/FlamesBackground.jpg") left top/cover fixed;
  padding: 100px 0;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1160px) {
  .MeetMakersCont {
    width: 80%;
  }
}

@media only screen and (max-width: 1020px) {
  .MeetMakersCont {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .MeetMakersCont {
    grid-template-columns: 250px 1fr;
    grid-template-rows: repeat(3, 40px) 1fr 50px;
    column-gap: 20px;
    row-gap: 0;
  }

  .BrandonDave {
    width: 250px;
  }

  .MakersTitle {
    grid-row: 1 / 2;
  }

  .MakersTitle2 {
    grid-row: 2 / 3;
  }

  .MakersTitle3 {
    grid-row: 3 / 4;
  }

  .AboutMakers {
    grid-row: 4 / 5;
  }

  .MeetMakersBtn {
    grid-row: 5 / 6;
  }
}

@media only screen and (max-width: 818px) {
  .MeetMakersCont {
    width: 70%;
    grid-template-columns: 1fr;
    grid-template-rows: 375px 20px repeat(3, 40px) 1fr 80px;
  }

  .BrandonDave {
    width: 250px;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .MakersTitle {
    font-size: 1.5rem;
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  .MakersTitle2 {
    font-size: 1.5rem;
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }

  .MakersTitle3 {
    font-size: 1.5rem;
    grid-row: 5 / 6;
    grid-column: 1 / 2;
  }

  .AboutMakers {
    grid-row: 6 / 7;
    grid-column: 1 / 2;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .MeetMakersBtn {
    grid-row: 7 / 8;
    grid-column: 1 / 2;
  }
}

@media only screen and (max-width: 760px) {
  .HeroTitle {
    font-size: 5rem;
  }

  .HeroTitleDesc {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .MeetMakersCont {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .HeroTitle {
    font-size: 3.5rem;
  }

  .HeroTitleDesc {
    font-size: 1.5rem;
  }
}