/* RESETS */
* {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lato:wght@700&family=Metal+Mania&family=Montserrat&family=Roboto:wght@500&display=swap');

:root {
  --black: rgb(0,0,0);
  --darkGrey: rgb(15,15,15);
  --Grey: rgb(129,129,129);
  --white: rgb(255,255,255);
  --FireYellow: rgb(253,204,14);
  --FacebookBlue: rgb(66,103,178);
  --TwitterBlue: rgb(44,170,225);
  --YoutubeRed: rgb(255,0,0);
  --metalMania: 'Metal Mania', cursive;
  --lato: 'Lato', sans-serif;
  --fjallaOne: 'Fjalla One', sans-serif;
  --roboto: 'Roboto', sans-serif;
  --montserrat: 'Montserrat', sans-serif;
}